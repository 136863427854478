import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { AuthModule } from '@auth/feature-auth';
import {
    BrowserTracing,
    Replay,
    TraceService,
    createErrorHandler,
    init,
    routingInstrumentation,
} from '@sentry/angular-ivy';
import {
    ENVIRONMENT,
    JwtInterceptor,
    LoadingInterceptor,
    ResponseErrorInterceptor,
} from '@shared/utils';
import { UiLibraryModule } from '@ui-library/ui-library.module';
import { ApiModule } from '@upscore-mobility-audit/api';
import { UpscoreMobilityAuditApiWrapperService } from '@upscore-mobility-audit/shared/api-services/api-wrapper.service';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';
import {
    DENY_URLS,
    IGNORE_ERRORS,
} from '@upscore-mobility-audit/shared/constants/sentry-ignore-errors.constant';
import { UpscoreSharedModule } from '@upscore-mobility-audit/shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import '@upscore-mobility-audit/shared/prototypes/observables-protoypes';

init({
    dsn: environment.sentryDsn,
    environment: environment.name,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: DENY_URLS,
    ignoreErrors: IGNORE_ERRORS,
    sendDefaultPii: true,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: routingInstrumentation,
        }),
        new Replay(),
    ],
    beforeSend(event, hint) {
        if (
            hint.originalException != undefined &&
            hint.originalException instanceof HttpErrorResponse
        ) {
            if (event.exception?.values?.[0] != undefined) {
                // save message in exception value
                event.exception.values[0].value = hint.originalException.message as string;
            }

            const frames = event.exception?.values?.[0]?.stacktrace?.frames ?? [];

            if ('code' in hint.originalException.error && frames && frames.length > 0) {
                // add error code as function param to the last frame
                frames[frames.length - 1].function = hint.originalException.error.code as string;
            }

            // store error object as extra
            event.extra = hint.originalException.error;

            // add requests string
            event.fingerprint = ['{{ default }}', event.exception?.values?.[0].value || ''];

            // Here we add the event_id to the error object so we can track it in the frontend
            (hint.originalException as HttpErrorResponse & { event_id?: string }).event_id =
                event.event_id;
        }

        return event;
    },
});

@NgModule({
    declarations: [AppComponent],
    imports: [
        ApiModule.forRoot({ rootUrl: `${environment.apiUrl}` }),
        AuthModule.forRoot({
            apiWrapperClass: UpscoreMobilityAuditApiWrapperService,
            userDataServiceClass: UpscoreMobilityAuditUserDataService,
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        BrowserModule,
        CommonModule,
        HttpClientModule,
        UpscoreSharedModule,
        UiLibraryModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
                extractor(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown {
                    if (
                        error != null &&
                        typeof error === 'object' &&
                        'rejection' in error &&
                        error['rejection'] instanceof HttpErrorResponse
                    ) {
                        // incase a promise fails with an HttpErrorResponse
                        return error['rejection'];
                    } else if (error instanceof HttpErrorResponse) {
                        return error;
                    } else {
                        return defaultExtractor(error);
                    }
                },
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => undefined,
            deps: [TraceService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseErrorInterceptor, multi: true },
        { provide: ENVIRONMENT, useValue: environment },
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
