import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    if (window) {
        // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
        window.console.log = () => {};
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(success => console.log(`Bootstrap success`))
    .catch(err => console.error(err));
