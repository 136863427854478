/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanyLocationOwner } from '../models/company-location-owner';
import { UserInfo } from '../models/user-info';

@Injectable({
  providedIn: 'root',
})
export class SharingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listAllViewOnlyUsersOfCompanyLocation
   */
  static readonly ListAllViewOnlyUsersOfCompanyLocationPath = '/api/v1/locations/{companyLocationId}/sharing';

  /**
   * List view only users of specific company location.
   *
   * fetches all the view only users who have the requested company location's id as a viewable company location.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllViewOnlyUsersOfCompanyLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllViewOnlyUsersOfCompanyLocation$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, SharingService.ListAllViewOnlyUsersOfCompanyLocationPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserInfo>>;
      })
    );
  }

  /**
   * List view only users of specific company location.
   *
   * fetches all the view only users who have the requested company location's id as a viewable company location.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAllViewOnlyUsersOfCompanyLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllViewOnlyUsersOfCompanyLocation(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<UserInfo>> {

    return this.listAllViewOnlyUsersOfCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserInfo>>) => r.body as Array<UserInfo>)
    );
  }

  /**
   * Path part for operation newViewOnlyUser
   */
  static readonly NewViewOnlyUserPath = '/api/v1/locations/{companyLocationId}/sharing';

  /**
   * Create new view only user and share company location with them.
   *
   * Create a new company location owner of type VIEW_ONLY (can only check the data and can't perform any action).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newViewOnlyUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  newViewOnlyUser$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * required unique email/username for new user
     */
    username: string;

    /**
     * Name of the user (Optional with default value Viewer)
     */
    name?: string;

    /**
     * Password of the user (Optional and will be automatically generated as an alpha numeric string of size 8 if parameter is null or empty)
     */
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CompanyLocationOwner>> {

    const rb = new RequestBuilder(this.rootUrl, SharingService.NewViewOnlyUserPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('username', params.username, {});
      rb.query('name', params.name, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocationOwner>;
      })
    );
  }

  /**
   * Create new view only user and share company location with them.
   *
   * Create a new company location owner of type VIEW_ONLY (can only check the data and can't perform any action).
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `newViewOnlyUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newViewOnlyUser(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * required unique email/username for new user
     */
    username: string;

    /**
     * Name of the user (Optional with default value Viewer)
     */
    name?: string;

    /**
     * Password of the user (Optional and will be automatically generated as an alpha numeric string of size 8 if parameter is null or empty)
     */
    password?: string;
    context?: HttpContext
  }
): Observable<CompanyLocationOwner> {

    return this.newViewOnlyUser$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocationOwner>) => r.body as CompanyLocationOwner)
    );
  }

  /**
   * Path part for operation resetViewOnlyUserPassword
   */
  static readonly ResetViewOnlyUserPasswordPath = '/api/v1/locations/{companyLocationId}/sharing/reset/{viewOnlyUserId}';

  /**
   * Reset view only user password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetViewOnlyUserPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetViewOnlyUserPassword$Response(params: {
    viewOnlyUserId: number;
    companyLocationId: number;
    newPassword: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CompanyLocationOwner>> {

    const rb = new RequestBuilder(this.rootUrl, SharingService.ResetViewOnlyUserPasswordPath, 'get');
    if (params) {
      rb.path('viewOnlyUserId', params.viewOnlyUserId, {});
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('newPassword', params.newPassword, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocationOwner>;
      })
    );
  }

  /**
   * Reset view only user password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetViewOnlyUserPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetViewOnlyUserPassword(params: {
    viewOnlyUserId: number;
    companyLocationId: number;
    newPassword: string;
    context?: HttpContext
  }
): Observable<CompanyLocationOwner> {

    return this.resetViewOnlyUserPassword$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocationOwner>) => r.body as CompanyLocationOwner)
    );
  }

  /**
   * Path part for operation deleteViewOnlyUser
   */
  static readonly DeleteViewOnlyUserPath = '/api/v1/locations/{companyLocationId}/sharing/{viewOnlyUserId}';

  /**
   * Delete view only user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteViewOnlyUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteViewOnlyUser$Response(params: {
    viewOnlyUserId: number;
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SharingService.DeleteViewOnlyUserPath, 'delete');
    if (params) {
      rb.path('viewOnlyUserId', params.viewOnlyUserId, {});
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete view only user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteViewOnlyUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteViewOnlyUser(params: {
    viewOnlyUserId: number;
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<{
}> {

    return this.deleteViewOnlyUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
