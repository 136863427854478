import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    AbstractApiWrapperService,
    AbstractUserDataService,
    LocalStorageKeys,
    PaginationControllerService,
} from '@shared/utils';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StrictHttpResponse, UserInfo } from './auth.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private readonly router: Router,
        private readonly apiWrapperService: AbstractApiWrapperService<
            StrictHttpResponse<UserInfo>,
            UserInfo
        >,
        private readonly paginationControllerService: PaginationControllerService,
        private userDataService: AbstractUserDataService<UserInfo>,
    ) {}

    private static isJwtTokenValid(token: string): boolean {
        const expiry: number = +JSON.parse(window.atob(token.split('.')[1])).exp;

        return expiry >= Math.floor(new Date().getTime() / 1000);
    }

    private static setJwtToken(token: string): void {
        localStorage.setItem(LocalStorageKeys.JWT_TOKEN, token);
    }

    private static clearLocalStorage(): void {
        localStorage.clear();
    }

    public login(username: string, password: string): Observable<string> {
        return this.apiWrapperService.loginPost(username, password).pipe(
            map(data => {
                const token: string = data.body?.jwtToken ?? '';
                if (data) {
                    this.userDataService.updateUserInfo(data.body);
                }
                if (token.length > 0) {
                    AuthService.setJwtToken(token);
                }

                return token;
            }),
            catchError(() => {
                throw Error('Login Failed');
            }),
        );
    }

    public logout(): void {
        this.userDataService.reset();
        AuthService.clearLocalStorage();
        this.paginationControllerService.clear();
        void this.router.navigate(['auth', 'login']);
    }

    public isLoggedIn(): boolean {
        const token: string = this.getJwtToken();

        return token === null ? false : AuthService.isJwtTokenValid(token);
    }

    public getJwtToken(): string {
        return localStorage.getItem(LocalStorageKeys.JWT_TOKEN) as string;
    }
}
