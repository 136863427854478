import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@auth/data-access-auth';
import { Observable } from 'rxjs';
import { Environment } from '../interfaces';
import { ENVIRONMENT } from '../services';

@Injectable({
    providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: AuthService,
        @Inject(ENVIRONMENT) private environment: Environment,
    ) {
        // nothing to do here
    }

    /**
     * Intercepts HttpRequests and adds Authorization Bearer token if user is logged in and api is called
     * @param request
     * @param next
     */
    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        const isLoggedIn: boolean = this.authService.isLoggedIn();
        const isApiUrl: boolean = request.url.startsWith(this.environment.apiUrl);

        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: this.authService.getJwtToken(),
                },
            });
        }

        return next.handle(request);
    }
}
