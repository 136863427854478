import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'placesInput',
})
export class PlacesInputPipe implements PipeTransform {
    public transform(placeResult: google.maps.places.PlaceResult, value: string): string {
        const place = placeResult.address_components?.find(p => p.types.includes(value));
        if (place == null) {
            return '';
        }

        return place.long_name;
    }
}
