/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeScenarioResultToRead } from '../models/employee-scenario-result-to-read';
import { ScenarioResultToSave } from '../models/scenario-result-to-save';

@Injectable({
  providedIn: 'root',
})
export class KpisService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getScenario
   */
  static readonly GetScenarioPath = '/api/v1/locations/{companyLocationId}/scenarioKpis';

  /**
   * Get a specific scenario (scenario result to save with employee scenario results).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScenario()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenario$Response(params: {
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ScenarioResultToSave>> {

    const rb = new RequestBuilder(this.rootUrl, KpisService.GetScenarioPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('scenarioReference', params.scenarioReference, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScenarioResultToSave>;
      })
    );
  }

  /**
   * Get a specific scenario (scenario result to save with employee scenario results).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScenario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenario(params: {
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<ScenarioResultToSave> {

    return this.getScenario$Response(params).pipe(
      map((r: StrictHttpResponse<ScenarioResultToSave>) => r.body as ScenarioResultToSave)
    );
  }

  /**
   * Path part for operation getEmployeeKpis
   */
  static readonly GetEmployeeKpisPath = '/api/v1/locations/{companyLocationId}/employeeKpis/{employeeId}';

  /**
   * Get Kpis of an employee for a specific scenario (employee scenario result).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeKpis()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeKpis$Response(params: {
    companyLocationId: number;
    employeeId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmployeeScenarioResultToRead>> {

    const rb = new RequestBuilder(this.rootUrl, KpisService.GetEmployeeKpisPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('employeeId', params.employeeId, {});
      rb.query('scenarioReference', params.scenarioReference, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeScenarioResultToRead>;
      })
    );
  }

  /**
   * Get Kpis of an employee for a specific scenario (employee scenario result).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeKpis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeKpis(params: {
    companyLocationId: number;
    employeeId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<EmployeeScenarioResultToRead> {

    return this.getEmployeeKpis$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeScenarioResultToRead>) => r.body as EmployeeScenarioResultToRead)
    );
  }

}
