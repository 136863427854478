import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    AbstractApiWrapperService,
    AbstractUserDataService,
    SharedUtilsModule,
} from '@shared/utils';
import { UiLibraryModule } from '@ui-library/ui-library.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';

@NgModule({
    declarations: [LoginComponent],
    imports: [
        AuthRoutingModule,
        CommonModule,
        ReactiveFormsModule,
        SharedUtilsModule,
        UiLibraryModule,
    ],
})
export class AuthModule {
    static forRoot(config: {
        apiWrapperClass: Type<AbstractApiWrapperService<any, any>>;
        userDataServiceClass: Type<AbstractUserDataService<any>>;
    }): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: AbstractApiWrapperService,
                    useClass: config.apiWrapperClass,
                },
                {
                    provide: AbstractUserDataService,
                    useClass: config.userDataServiceClass,
                },
            ],
        };
    }
}
