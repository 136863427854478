/*
 * Usage Guide:
 *
 * 1. Add  a new object to the errors object.
 * 2. Check if there are additional properties to be added to the object.
 * 3. If you want to use these properties write a lambda function to inject these into the error message (body).

 * If no title is given the body will be shown instead and there will be no details button.
 */

import { HttpError } from '../interfaces';

export const httpErrors: { [key: string]: HttpError } = {
    ENTRANCE_IN_USE: {
        title: $localize`:@@entranceInUseTitle:Entrance in use`,
        body: $localize`:@@entranceInUseDescription:The deleted entrance contains employees, please remove them before removing the entrance.`,
        sticky: false,
    },
    EMPLOYEE_DOES_NOT_EXIST: {
        title: $localize`:@@http-errors-title:Error`,
        body: properties => {
            const employeeId: number = properties.requestedEmployeeId;

            return $localize`:@@employeeDoesNotExist:Employee with id ${employeeId} does not exist.`;
        },
        sticky: false,
    },
    COMPANY_LOCATION_DOES_NOT_EXIST: {
        title: $localize`:@@http-errors-title:Error`,
        body: properties => {
            const locationId: number = properties.requestedCompanyLocationId;

            return $localize`:@@companyLocationDoesNotExist:Company location with id ${locationId} does not exist.`;
        },
        sticky: false,
    },
    ACCESS_TO_COMPANY_LOCATION_NOT_ALLOWED: {
        title: $localize`:@@http-errors-title:Error`,
        body: properties => {
            const locationId: number = properties.requestedCompanyLocationId;

            return $localize`:@@accessToCompanyLocationWithIDNotAllowed:Access to company location with id ${locationId} not allowed.`;
        },
        sticky: false,
    },
    QUOTA_LIMIT_REACHED: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@quotaLimitReached:You reached the maximum number of Mobility Audits allocated for you.`,
        sticky: false,
    },
    UPLOADED_FILE_IS_INVALID: {
        title: $localize`:@@http-errors-title:Error`,
        body: properties => {
            return $localize`:@@uploadedFileInvalid:Uploaded file is invalid at line ${
                properties.line + 1
            } and column ${properties.column}.`;
        },
        sticky: false,
    },
    NO_EMPLOYEE_EXISTS: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@noEmployeeExists:Company Locations needs to have employees to for a Mobility Score calculation.`,
        sticky: false,
    },
    COMPANY_LOCATION_OUT_OF_COVERED_AREA: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@companyLocationOutCoveredArea:Company location is out of covered area.`,
        sticky: false,
    },
    EMPLOYEE_OUT_OF_COVERED_AREA: {
        title: $localize`:@@http-errors-title:Error`,
        body: properties => {
            let errorString: string = $localize`:@@followingEmployeesAreOutOfCoverArea:The following employees are out of covered area:`;
            for (const location of properties.nonCoveredEmployees) {
                errorString +=
                    $localize`:@@outOfCoveredAreaLocation:\nlocation:` +
                    ` ${location.street} ${location.country}`;
            }

            return errorString;
        },
        sticky: true,
    },
    NOT_ENOUGH_CALCULATION_DATA: {
        title: $localize`:@@wrongCalculationData:Error - Wrong data for calculation`,
        body: properties => {
            // UNAVAILABLE
            // DISABLED
            const unavailableModalErrors: { [mode: string]: number } = {};
            const disabledModalErrors: { [mode: string]: number } = {};
            for (const error of properties.unknownDataEmployees) {
                unavailableModalErrors[error.mode] = 0;
                disabledModalErrors[error.mode] = 0;
            }
            for (const error of properties.unknownDataEmployees) {
                if (error.reason === 'UNAVAILABLE') {
                    unavailableModalErrors[error.mode] += 1;
                } else {
                    disabledModalErrors[error.mode] += 1;
                }
            }

            let errorString: string = $localize`:@@followingModesConfiguredWrong:The following modes are configured wrong:`;
            for (const mode in unavailableModalErrors) {
                if (unavailableModalErrors[mode] > 0) {
                    errorString += $localize`:@@modeUnavailable:\n${unavailableModalErrors[mode]} people in ${mode} are unavailable`;
                }
            }
            for (const mode in disabledModalErrors) {
                if (disabledModalErrors[mode] > 0) {
                    errorString += $localize`:@@modeDisabled:\n${disabledModalErrors[mode]} people in ${mode} are disabled`;
                }
            }

            return errorString;
        },
        sticky: true,
    },
    MAX_EMPLOYEES_ALLOWED_EXCEEDED: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@maxEmployeesAllowedExceeded:You reached the maximum number of employees allowed for this Mobility Audit.`,
        sticky: false,
    },
    MODAL_SPLIT_SUGGESTION_ERROR: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@modalSplitSuggestionError:Suggesting Modal Split failed.`,
        sticky: false,
    },
    DETAILED_TRIP_TYPE_NOT_ALLOWED: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@detailedTripTypeNotAllowed:Detailed trip type is not allowed when detailed trip is set!`,
        sticky: false,
    },
    INVALID_PAGE_OR_SIZE: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@invalidPageOrSizeBug:You found a bug in the application. Please contact the administrator with information how to reproduce it.\nError: INVALID_PAGE_OR_SIZE`,
        sticky: true,
    },
    NO_EMPLOYEE_HAD_WORKING_HOURS: {
        title: $localize`:@@csvError:CSV Error`,
        body: $localize`:@@noEmployeeHasWorkingHours:No employee has working hours defined.\nPlease specify more.`,
        sticky: false,
    },
    NOT_ALL_EMPLOYEES_HAVE_OPTIMAL_MODE: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@checkOutStatusQuoFirst:Please checkout status-quo first.`,
        sticky: false,
    },
    ESTIMATED_CARPOOLS_NOT_FOUND: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@checkOutStatusQuoFirst:Please checkout status-quo first.`,
        sticky: false,
    },
    ENTRANCE_DOES_NOT_EXIST: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@entranceDoesNotExist:Entrance you are trying to modify does not exist.`,
        sticky: false,
    },
    HIGHER_LEVEL_REQUIRED_SERVICE: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@upgradeLicense:This service is only available for higher tier users.\nIf you want to upgrade your license contact team@mobility-audit.com`,
        sticky: true,
    },
    COMPANY_LOCATION_UPDATE_ERROR: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@companyLocationUpdateError:Company location couldn't be updated - please try again.`,
        sticky: false,
    },
    INVALID_ENTRY_OR_EXIT_TIME: {
        title: $localize`:@@csvError:CSV Error`,
        body: properties => {
            return $localize`:@@invalidEntryOrExitTime:Invalid entry or exit time defined in CSV file. Check employee in line or with id: ${properties.employeeNumber}.`;
        },
        sticky: true,
    },
    INVALID_CSV_DELIMITER: {
        title: $localize`:@@csvError:CSV Error`,
        body: $localize`:@@invalidCsvDelimiter:Invalid CSV Delimiter Detected. Please ensure that your CSV file uses a valid delimiter such as a comma (,), semicolon (;).`,
        sticky: true,
    },
    PACKAGE_UPDATE_ERROR: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@packageUpdateError:Package couldn't be updated - please try again.`,
        sticky: false,
    },
    PACKAGE_DOES_NOT_EXIST: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@packageDoesNotExist:Package you are trying to modify does not exist.`,
        sticky: false,
    },
    MOBILITY_OR_VEHICLE_STATS_CONSTRAINT_VIOLATION: {
        sticky: true,
        title: $localize`:@@constraintViolation:Error - Constraint violation`,
        body: $localize`:@@sumOfStatsMustBe1:The sum of all mobility and vehicle stats must be 100%.`,
    },
    EMPTY_OR_NULL_REQUIRED_PARAMETER: {
        sticky: true,
        title: $localize`:@@http-errors-title:Error`,
        body: properties => {
            return $localize`:@@emptyOrNullRequiredParameter:The parameter ${properties.parameterName} is required.`;
        },
    },
    NO_LOCATION_PROPERTIES: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@noLocationProperties:No location properties found.`,
        ignore: true,
    },
    NO_USER_PROPERTIES: {
        title: $localize`:@@http-errors-title:Error`,
        body: $localize`:@@noUserProperties:No user properties found.`,
        ignore: true,
    },
    FEATURE_DISABLED: {
        title: $localize`:@@feature-not-available-error:Feature not available`,
        body: $localize`:@@featureDisabled:The feature you are trying to use is not available at the moment.`,
    },
    ALL_EMPLOYEES_FAILED_GEOCODING: {
        title: $localize`:@@allEmployeesFailedGeocoding:Employee upload failed`,
        body: $localize`:@@allEmployeesFailedGeocodingDescription:Server couldn't save employees.`,
    },
    DISTANCE_CALCULATION_ERROR: {
        title: $localize`:@@distanceCalculationError:Calculation Error`,
        body: $localize`:@@distanceCalculationErrorDescription:Server failed when calculation distances.`,
    },
    USER_WITH_ALREADY_ASSIGNED_MOBILITY_PROPERTIES: {
        title: $localize`:@@userAlreadyAssignedMobilityProperties:Mobility properties already present`,
    },
    LOCATION_WITH_ALREADY_ASSIGNED_MOBILITY_PROPERTIES: {
        title: $localize`:@@locationAlreadyAssignedMobilityProperties:Mobility properties already present`,
    },
    SERVICE_IS_NOT_ALLOWED_IN_THIS_AREA: {
        title: $localize`:@@serviceNotAllowed:Service is not allowed in this area`,
    },
    LOGIN_FAILED: {
        title: $localize`:@@loginFailure:Login Failed`,
        body: parameters => {
            switch (parameters.reason) {
                case 'bad_credentials':
                    return $localize`:@@badCredentials:The user name or password are incorrect`;
                case 'user_expired':
                    return $localize`:@@userExpired:Your account has expired`;
                default: // unknown, null etc
                    return $localize`:@@unknownError:Unknown error. Please contact support`;
            }
        },
        showRawMessage: false,
        report: false,
    },
    INVALID_EMPLOYEES_COUNT: {
        title: $localize`:@@invalidEmployeeCount:When estimating employees, the count has to be at least 10 and lower than 10_000`,
    },
    NO_COMMUTERS_FOUND: {
        title: $localize`:@@employeeEstimationFailed:Employee Estimation failed`,
        body: $localize`:@@noCommutersNearTheLocation:Employees can’t be estimated for this location, no incoming commuters nearby`,
    },
    NO_MODES_ASSIGNABLE: {
        body: parameters =>
            $localize`:@@modesCantBeAssigned:Couldn't assign modes to ${parameters.employeeIds.length} employees`,
    },
    NO_TRIPS_FOR_SELECTED_MODE: {
        title: $localize`:@@noTripsForEmployees:No trips for employees`,
        body: parameters => {
            const idModes: { [id: string]: string } = parameters;
            let output = 'These employees have no trips for selected mode:\n';
            for (const id in idModes) {
                output += $localize`:@@employee:Employee` + `#${id} with mode ${idModes[id]}\n`;
            }

            return output;
            // $localize`:@@noTripsForSelectedMode:No trips for selected mode for ${parameters.employeeIds.length} employees`
        },
    },
    SCENARIO_DOES_NOT_EXIST: {
        title: $localize`:@@scenarioDoesNotExistTitle:Scenario does not exist`,
        body: parameters =>
            $localize`:@@scenarioDoesNotExistBody:Scenario with id ${parameters.scenarioReferenceRequested} does not exist`,
    },
    ENTRANCE_NOT_FOUND: {
        title: $localize`:@@entranceNotFoundTitle:Entrance not found`,
        body: parameters =>
            $localize`:@@entranceNotFoundBody:Entrance with id ${parameters.entranceNumber} does not exist`,
    },
    'score_calculation.modes_not_assignable': {
        title: $localize`:@@modesNotAssignableTitle:Modes were not assignable for some employees`,
        body: parameters =>
            $localize`:@@modesNotAssignableBody:Could not assign modes with ID's ${(parameters.realIds
                ? parameters.realIds
                : parameters.problematicEmployeeIds
            ).join(', ')}`,
    },
    R5CONNECTORS_NOT_SET_OR_POINT_NOT_COVERED: {
        title: $localize`:@@r5ConnectorsNotSetOrPointNotCoveredTitle:Unfortunately, accessibility areas for this point can’t be calculated automatically.`,
        body: $localize`:@@r5ConnectorsNotSetOrPointNotCoveredBody:Please contact dev@triply.at to request a calculation. You can still view employee’s modes on the map and see calculated KPIs.`,
    },
    AUTHENTICATION_FAILED: {
        body: parameters => {
            switch (parameters.reason) {
                case 'JWT_EXPIRED':
                    return $localize`:@@sessionExpired:Your session has expired. Please log in again.`;
                case 'JWT_PARSING_FAILED':
                    return $localize`:@@jwtParsingFailed:Authentication failed. Please log in again.`;
                default: // unknown, null etc
                    return $localize`:@@unknownError:Unknown error. Please contact support`;
            }
        },
        report: false,
    },
};
