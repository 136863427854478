/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeIdAndMode } from '../models/employee-id-and-mode';

@Injectable({
  providedIn: 'root',
})
export class EmployeeModeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getEmployeeModesByScenario
   */
  static readonly GetEmployeeModesByScenarioPath = '/api/v1/locations/{companyLocationId}/employeeModes';

  /**
   * Map employees to their corresponding modes based on a scenario.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeModesByScenario()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeModesByScenario$Response(params: {
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EmployeeIdAndMode>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeModeService.GetEmployeeModesByScenarioPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('scenarioReference', params.scenarioReference, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeIdAndMode>>;
      })
    );
  }

  /**
   * Map employees to their corresponding modes based on a scenario.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeModesByScenario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeModesByScenario(params: {
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<Array<EmployeeIdAndMode>> {

    return this.getEmployeeModesByScenario$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeIdAndMode>>) => r.body as Array<EmployeeIdAndMode>)
    );
  }

}
