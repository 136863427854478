import { LanguageKeys } from '../enums';
import { Language } from '../interfaces';

export const languages: { [key in LanguageKeys]: Language } = {
    DE: {
        name: 'Deutsch',
        path: 'de',
        image: 'de',
    },
    EN: {
        name: 'English',
        path: 'en',
        image: 'gb',
    },
    FR: {
        name: 'France',
        path: 'fr',
        image: 'fr',
    },
};
