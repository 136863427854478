import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor() {
        // nothing left to do
    }

    /**
     * get hex code from a css variable name
     * @param cssVarName
     */
    public getHexFromCSSVariable(cssVarName: string): string {
        return getComputedStyle(document.body).getPropertyValue(cssVarName);
    }

    public getModeColorCSSVariable(mode: string): string {
        switch (mode) {
            case 'WALK':
                return '--walk-color';
            case 'BIKE':
                return '--bike-color';
            case 'WAIT':
                return '#fff';
            case 'CAR_DRIVER_TRAFFIC':
                return '--danger-color';
            case 'CAR_DRIVER':
                return '--car-driver-color';
            case 'CAR':
                return '--car-driver-color';
            case 'CAR_PASSENGER':
                return '--car-passenger-color';
            case 'PUBLIC_TRANSPORT':
                return '--public-transport-color';
            default:
                return '--public-transport-color';
        }
    }

    /* Get color based on trip mode */
    public getModeColor(mode: string): string {
        return this.getHexFromCSSVariable(this.getModeColorCSSVariable(mode));
    }
}
