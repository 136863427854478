import { NgModule, inject } from '@angular/core';
import { Route, RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthGuard } from '@auth/feature-auth';
import { NotFoundComponent } from '@ui-library/components';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('@auth/feature-auth').then(m => m.AuthModule),
        canMatch: [() => inject(AuthGuard).isLoggedOut()],
        data: {
            redirect: '/audits',
        },
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'audits',
    },
    {
        path: 'audits',
        canMatch: [
            (route: Route, segments: UrlSegment[]) => inject(AuthGuard).isLoggedIn(route, segments),
        ],
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () =>
                    import('@upscore-mobility-audit/dashboard/dashboard.module').then(
                        m => m.DashboardModule,
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('@upscore-mobility-audit/core/core.module').then(m => m.CoreModule),
            },
        ],
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
