import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@ui-library/modules/material/material.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { BasicOverlayComponent } from './components/basic-overlay/basic-overlay.component';
import { CalloutComponent } from './components/callout/callout.component';
import { CompanyLocationHeaderComponent } from './components/company-location-header/company-location-header.component';
import { CompanyLocationHeaderDropdownsComponent } from './components/company-location-header-dropdowns/company-location-header-dropdowns.component';
import { EmployeeMarkerToggleComponent } from './components/employee-marker-toggle/employee-marker-toggle.component';
import { ExpandableSectionComponent } from './components/expandable-section/expandable-section.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HelpPopoverComponent } from './components/help-popover/help-popover.component';
import { InfoComponent } from './components/info/info.component';
import { KpiMeasureComponent } from './components/kpi-measure/kpi-measure.component';
import { PageTabsComponent } from './components/page-tabs/page-tabs.component';
import { UpscoreSimpleTableComponent } from './components/upscore-simple-table/upscore-simple-table.component';
import { UpscoreSliderComponent } from './components/upscore-slider/upscore-slider.component';
import { UpscoreSliderInputComponent } from './components/upscore-slider-input/upscore-slider-input.component';
import { UpscoreTableComponent } from './components/upscore-table/upscore-table.component';
import { ErrorCancelContinueDialogComponent } from './dialogs/error-cancel-continue-dialog/error-cancel-continue-dialog.component';
import { HeaderLessTabsDirective } from './directives/header-less-tabs.directive';
import { InputNumberRangeDirective } from './directives/input-number-range.directive';
import { NumberRangeDirective } from './directives/number-range.directive';
import { PopoverHoverDirective } from './directives/popover-hover.directive';
import { PopoverSelectDirective } from './directives/popover-select.directive';
import { CastPipe } from './pipes/cast.pipe';
import { DailyKpiPipe } from './pipes/daily-kpi.pipe';
import { FormControlPipe } from './pipes/form-control.pipe';
import { FormGroupPipe } from './pipes/form-group.pipe';
import { ModeTextTranslatePipe } from './pipes/mode-text-translate.pipe';
import { PercentageChangePipe } from './pipes/percentage-change.pipe';

@NgModule({
    declarations: [
        UpscoreSliderComponent,
        PageTabsComponent,
        PageTabsComponent,
        FeedbackComponent,
        DailyKpiPipe,
        KpiMeasureComponent,
        PercentageChangePipe,
        FormControlPipe,
        FormGroupPipe,
        ModeTextTranslatePipe,
        InfoComponent,
        ExpandableSectionComponent,
        CompanyLocationHeaderComponent,
        UpscoreSimpleTableComponent,
        UpscoreTableComponent,
        UpscoreSliderInputComponent,
        PopoverSelectDirective,
        CompanyLocationHeaderDropdownsComponent,
        HeaderLessTabsDirective,
        UpscoreSimpleTableComponent,
        HelpPopoverComponent,
        PopoverHoverDirective,
        CalloutComponent,
        NumberRangeDirective,
        InputNumberRangeDirective,
        ErrorCancelContinueDialogComponent,
        CastPipe,
        EmployeeMarkerToggleComponent,
        BasicOverlayComponent,
    ],
    providers: [
        DecimalPipe,
        // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategyService },
    ],
    imports: [
        ToastrModule.forRoot({
            toastComponent: FeedbackComponent,
            preventDuplicates: true,
            countDuplicates: true,
        }),
        CommonModule,
        HttpClientModule,
        RouterModule,
        MaterialModule,
        CdkStepperModule,
        NgxMasonryModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        CarouselModule,
        NgOptimizedImage,
    ],
    exports: [
        RouterModule,
        UpscoreSliderComponent,
        MaterialModule,
        CdkStepperModule,
        CommonModule,
        PageTabsComponent,
        NgxMasonryModule,
        DailyKpiPipe,
        KpiMeasureComponent,
        PercentageChangePipe,
        ModeTextTranslatePipe,
        InfoComponent,
        ExpandableSectionComponent,
        CompanyLocationHeaderComponent,
        UpscoreSimpleTableComponent,
        UpscoreTableComponent,
        UpscoreSliderInputComponent,
        PopoverSelectDirective,
        CompanyLocationHeaderDropdownsComponent,
        HeaderLessTabsDirective,
        NumberRangeDirective,
        FormControlPipe,
        FormGroupPipe,
        HelpPopoverComponent,
        PopoverHoverDirective,
        CalloutComponent,
        InputNumberRangeDirective,
        ErrorCancelContinueDialogComponent,
        CastPipe,
        EmployeeMarkerToggleComponent,
        BasicOverlayComponent,
    ],
})
export class UpscoreSharedModule {}
