export enum FeatureFlag {
    MAX_SLIDER_VALUES = 'maxSliderValues',
    EMPLOYEE_TRIP_VISUALISATION = 'employeeTripVisualisation',
    GEOCODING_QUALITY = 'geocodingQuality',
    CALCULATION_PROPERTIES = 'configureCalculationProperties',
    MODE_ESTIMATION_V2 = 'newModeEstimation',
    DEBUG_VIEW = 'debugView',
    SHARE_COMPANY_LOCATION = 'shareCompanyLocation',
    EXPORT_KPIS = 'exportKPIs',
    MEASURE_SUGGESTION = 'measureSuggestion',
    DISPLAY_RAW_ERRORS = 'displayRawErrors',
    IS_ALLOWED_TO_ANALYSE = 'isAllowedToAnalyse',
}
