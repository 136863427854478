/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ExportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation exportCompanyLocationMetrics
   */
  static readonly ExportCompanyLocationMetricsPath = '/api/v1/export/{companyLocationId}';

  /**
   * Export scenario-specific calculation results for a mobility model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCompanyLocationMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocationMetrics$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ExportService.ExportCompanyLocationMetricsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('scenarioReference', params.scenarioReference, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Export scenario-specific calculation results for a mobility model.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCompanyLocationMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocationMetrics(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportCompanyLocationMetrics$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exportMetricsAsCsv
   */
  static readonly ExportMetricsAsCsvPath = '/api/v1/export/{companyLocationId}.csv';

  /**
   * Export scenario-specific calculation results for a mobility model as csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportMetricsAsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportMetricsAsCsv$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ExportService.ExportMetricsAsCsvPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('scenarioReference', params.scenarioReference, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Export scenario-specific calculation results for a mobility model as csv.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportMetricsAsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportMetricsAsCsv(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    scenarioReference: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportMetricsAsCsv$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exportGeneralCompanyLocationMetrics
   */
  static readonly ExportGeneralCompanyLocationMetricsPath = '/api/v1/export/generalMetrics/{companyLocationId}';

  /**
   * Export general calculation results for a mobility model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportGeneralCompanyLocationMetrics()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeneralCompanyLocationMetrics$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ExportService.ExportGeneralCompanyLocationMetricsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Export general calculation results for a mobility model.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportGeneralCompanyLocationMetrics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeneralCompanyLocationMetrics(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportGeneralCompanyLocationMetrics$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exportGeneralMetricsAsCsv
   */
  static readonly ExportGeneralMetricsAsCsvPath = '/api/v1/export/generalMetrics/{companyLocationId}.csv';

  /**
   * Export scenario-specific calculation results for a mobility model as csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportGeneralMetricsAsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeneralMetricsAsCsv$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ExportService.ExportGeneralMetricsAsCsvPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Export scenario-specific calculation results for a mobility model as csv.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportGeneralMetricsAsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportGeneralMetricsAsCsv(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportGeneralMetricsAsCsv$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
