import { Injectable } from '@angular/core';
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    LinearScale,
} from 'chart.js';
import { each } from 'chart.js/helpers';

Chart.register(
    DoughnutController,
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
);
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unsafe-call */
/* eslint-disable  @typescript-eslint/no-unsafe-member-access */
/* eslint-disable  @typescript-eslint/restrict-plus-operands */
/* eslint-disable  @typescript-eslint/no-magic-numbers */
/* eslint-disable  @typescript-eslint/no-unsafe-argument */

/* eslint-disable  @typescript-eslint/no-unsafe-assignment */

class RoundedDoughnut extends DoughnutController {
    // We have to extend the normal doughnut chart and implement rounded corners
    // more info: https://stackoverflow.com/questions/36934967/chart-js-doughnut-with-rounded-edges
    public override draw(): void {
        const ctx: CanvasRenderingContext2D = this.chart.ctx;
        const arcs: { [key: string]: any } = this.getMeta().data;
        each(arcs, (arc: any, i: any) => {
            arc.draw(this.chart.ctx);

            const radius: any = (arc.outerRadius + arc.innerRadius) / 2;
            const thickness: any = (arc.outerRadius - arc.innerRadius) / 2;
            const startAngle: any = Math.PI - arc.startAngle - Math.PI / 2;
            const angle: any = Math.PI - arc.endAngle - Math.PI / 2;

            ctx.save();
            ctx.translate(arc.x, arc.y);

            ctx.fillStyle = arc.options.backgroundColor;
            ctx.beginPath();
            ctx.arc(
                radius * Math.sin(startAngle),
                radius * Math.cos(startAngle),
                thickness,
                0,
                2 * Math.PI,
            );
            ctx.fill();

            ctx.fillStyle = arc.options.backgroundColor;
            ctx.beginPath();
            ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
            ctx.fill();

            ctx.restore();
        });
    }
}

RoundedDoughnut.id = 'RoundedDoughnut';
RoundedDoughnut.defaults = DoughnutController.defaults;

@Injectable({
    providedIn: 'root',
})
export class ChartJsService {
    /**
     * init method
     */
    public init(): void {
        this.initRoundedDoughnutChart();
    }

    /**
     * init rounded doughnut chart
     */
    private initRoundedDoughnutChart(): void {
        Chart.registry.add(RoundedDoughnut);
    }
}
