/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CarpoolingInterventionImpact } from '../models/carpooling-intervention-impact';
import { CustomImprovement } from '../models/custom-improvement';
import { CustomImprovementImpact } from '../models/custom-improvement-impact';
import { FreeBikeImprovementSuggestion } from '../models/free-bike-improvement-suggestion';
import { FreeBikesImprovementImpact } from '../models/free-bikes-improvement-impact';
import { FreeJobTicketsImprovementImpact } from '../models/free-job-tickets-improvement-impact';
import { HomeOfficeDaysImprovementImpact } from '../models/home-office-days-improvement-impact';
import { HomeOfficeImprovementSuggestion } from '../models/home-office-improvement-suggestion';
import { ImprovementSuggestion } from '../models/improvement-suggestion';
import { InterventionImpact } from '../models/intervention-impact';
import { JobTicketImprovementSuggestion } from '../models/job-ticket-improvement-suggestion';
import { MobilityScenarioDto } from '../models/mobility-scenario-dto';
import { PackageResponse } from '../models/package-response';

@Injectable({
  providedIn: 'root',
})
export class ImprovementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation editPackage
   */
  static readonly EditPackagePath = '/api/v1/locations/{companyLocationId}/improvements/editPackage/{packageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editPackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editPackage$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.EditPackagePath, 'put');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editPackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editPackage(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<PackageResponse> {

    return this.editPackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation getImprovementImpacts
   */
  static readonly GetImprovementImpactsPath = '/api/v1/locations/{companyLocationId}/improvements/getImprovementImpacts';

  /**
   * Get improvement impacts.
   *
   * calculate the impact of one or many posted improvement suggestions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImprovementImpacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  getImprovementImpacts$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>
  }
): Observable<StrictHttpResponse<Array<InterventionImpact>>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.GetImprovementImpactsPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InterventionImpact>>;
      })
    );
  }

  /**
   * Get improvement impacts.
   *
   * calculate the impact of one or many posted improvement suggestions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImprovementImpacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  getImprovementImpacts(params: {
    companyLocationId: number;
    context?: HttpContext
    body: Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>
  }
): Observable<Array<InterventionImpact>> {

    return this.getImprovementImpacts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InterventionImpact>>) => r.body as Array<InterventionImpact>)
    );
  }

  /**
   * Path part for operation getImprovementImpact
   */
  static readonly GetImprovementImpactPath = '/api/v1/locations/{companyLocationId}/improvements/getImprovementImpact';

  /**
   * Get improvement impact.
   *
   * calculate the impact of a posted improvement suggestion
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImprovementImpact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  getImprovementImpact$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: (CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)
  }
): Observable<StrictHttpResponse<InterventionImpact>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.GetImprovementImpactPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionImpact>;
      })
    );
  }

  /**
   * Get improvement impact.
   *
   * calculate the impact of a posted improvement suggestion
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImprovementImpact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  getImprovementImpact(params: {
    companyLocationId: number;
    context?: HttpContext
    body: (CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)
  }
): Observable<InterventionImpact> {

    return this.getImprovementImpact$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionImpact>) => r.body as InterventionImpact)
    );
  }

  /**
   * Path part for operation estimateImpact
   */
  static readonly EstimateImpactPath = '/api/v1/locations/{companyLocationId}/improvements/estimateImpact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateImpact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateImpact$Response(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
    body: Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>
  }
): Observable<StrictHttpResponse<Array<(CarpoolingInterventionImpact | CustomImprovementImpact | FreeBikesImprovementImpact | FreeJobTicketsImprovementImpact | HomeOfficeDaysImprovementImpact)>>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.EstimateImpactPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('baseScenario', params.baseScenario, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(CarpoolingInterventionImpact | CustomImprovementImpact | FreeBikesImprovementImpact | FreeJobTicketsImprovementImpact | HomeOfficeDaysImprovementImpact)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `estimateImpact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateImpact(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
    body: Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>
  }
): Observable<Array<(CarpoolingInterventionImpact | CustomImprovementImpact | FreeBikesImprovementImpact | FreeJobTicketsImprovementImpact | HomeOfficeDaysImprovementImpact)>> {

    return this.estimateImpact$Response(params).pipe(
      map((r: StrictHttpResponse<Array<(CarpoolingInterventionImpact | CustomImprovementImpact | FreeBikesImprovementImpact | FreeJobTicketsImprovementImpact | HomeOfficeDaysImprovementImpact)>>) => r.body as Array<(CarpoolingInterventionImpact | CustomImprovementImpact | FreeBikesImprovementImpact | FreeJobTicketsImprovementImpact | HomeOfficeDaysImprovementImpact)>)
    );
  }

  /**
   * Path part for operation createPackage
   */
  static readonly CreatePackagePath = '/api/v1/locations/{companyLocationId}/improvements/createPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPackage$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.CreatePackagePath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPackage(params: {
    companyLocationId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<PackageResponse> {

    return this.createPackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation getSuggestedMeasures
   */
  static readonly GetSuggestedMeasuresPath = '/api/v1/locations/{companyLocationId}/improvements/suggestedMeasures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedMeasures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedMeasures$Response(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.GetSuggestedMeasuresPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('baseScenario', params.baseScenario, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSuggestedMeasures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedMeasures(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
  }
): Observable<Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>> {

    return this.getSuggestedMeasures$Response(params).pipe(
      map((r: StrictHttpResponse<Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>>) => r.body as Array<(CustomImprovement | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>)
    );
  }

  /**
   * Path part for operation suggestedImprovements
   */
  static readonly SuggestedImprovementsPath = '/api/v1/locations/{companyLocationId}/improvements/suggestedImprovements';

  /**
   * Get suggested improvement templates.
   *
   * fetch improvement suggestions as pre-defined measures
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestedImprovements()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  suggestedImprovements$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ImprovementSuggestion>>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.SuggestedImprovementsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImprovementSuggestion>>;
      })
    );
  }

  /**
   * Get suggested improvement templates.
   *
   * fetch improvement suggestions as pre-defined measures
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suggestedImprovements$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  suggestedImprovements(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<ImprovementSuggestion>> {

    return this.suggestedImprovements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ImprovementSuggestion>>) => r.body as Array<ImprovementSuggestion>)
    );
  }

}
