import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@shared/utils';
import { ChartJsService } from '@upscore-mobility-audit/shared/services/chart-js.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public loading = false;
    private unsubscribe$: Subject<void> = new Subject();

    // LanguageSwitcherService needs to be loaded here
    constructor(
        public loadingService: LoadingService,
        private readonly chartJsService: ChartJsService,
    ) {
        // Nothing to do here
        this.chartJsService.init();
    }

    /**
     * Angular lifecycle
     * @method ngOnInit
     */
    public ngOnInit(): void {
        // init loading
        this.loadingService
            .loading()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: boolean) => {
                this.loading = value;
            });
    }

    /**
     * Angular lifecycle
     * @method ngOnDestroy
     */
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
