import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { configureScope } from '@sentry/angular-ivy';
import { AbstractApiWrapperService, AbstractUserDataService } from '@shared/utils';
import { take } from 'rxjs/operators';
import { StrictHttpResponse, UserInfo } from './auth.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class ResolveUserDataService {
    private hasResolved = false;

    constructor(
        private readonly router: Router,
        private apiWrapperService: AbstractApiWrapperService<
            StrictHttpResponse<UserInfo>,
            UserInfo
        >,
        private readonly userDataService: AbstractUserDataService<UserInfo>,
        private readonly authService: AuthService,
    ) {}

    /**
     * resolve route
     */
    public resolve(): Promise<void> {
        return new Promise(resolve => {
            if (this.hasResolved) {
                resolve();

                return;
            }

            this.apiWrapperService
                .getUserInfo()
                .pipe(take(1))
                .subscribe({
                    next: userInfo => {
                        this.userDataService.updateUserInfo(userInfo);
                        this.hasResolved = true;
                        configureScope(scope => {
                            scope.setUser({
                                id: userInfo.id?.toString(),
                                username: userInfo.name,
                                email: userInfo.email,
                            });
                        });
                        resolve();
                    },
                    error: () => {
                        this.authService.logout();
                        void this.router.navigate(['auth', 'login']);
                        resolve();
                    },
                });
        });
    }
}
