import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export abstract class AbstractApiWrapperService<T, U> {
    abstract loginPost(username: string, password: string): Observable<T>;
    abstract getUserInfo(): Observable<U>;
}
