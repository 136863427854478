import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AbstractUserDataService } from '../abstract-api-services';
import { httpErrors } from '../constants';
import { FeatureFlag } from '../enums';
import { HttpError } from '../interfaces';
import { ResponseErrorTranslations } from '../translations';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorToastService {
    constructor(
        private readonly toastService: ToastService,
        private readonly userDataService: AbstractUserDataService<{
            jwtToken: string;
            name: string;
        }>,
    ) {
        // Nothing to do here
    }

    public async toastError(
        error: HttpErrorResponse,
        forceOpen = false,
        sentryEventId?: string,
    ): Promise<void> {
        let displayRawErrors = this.userDataService.user
            ? await firstValueFrom(
                  this.userDataService.showFeature$(FeatureFlag.DISPLAY_RAW_ERRORS),
              )
            : false;

        if (error.error instanceof ErrorEvent) {
            this.toastService.showInfoEvent(
                ResponseErrorTranslations.error,
                true,
                true,
                ResponseErrorTranslations.failedAction,
                '',
                false,
                displayRawErrors ? error : null,
                true,
                sentryEventId,
                error,
            );
            // client-side error
            // retry request
            // rxjs retry function
        } else {
            // server side error
            const statusCode: number = (error as HttpErrorResponse).status;
            const rawMessage: Record<string, string> = error.error;
            const errorCode: string = error.error.code;
            const additionalProperties: Record<string, string> =
                error.error.additionalProperties ?? {};
            const httpErrorDescription: HttpError = httpErrors[errorCode];

            if (!httpErrorDescription) {
                // If errorCode is null it means we have an exception without error code so for example a 401 unauthorized.
                this.toastService.showInfoEvent(
                    ResponseErrorTranslations.error,
                    true,
                    true,
                    ResponseErrorTranslations.failedRequest,
                    '',
                    false,
                    displayRawErrors ? rawMessage : null,
                    true,
                    sentryEventId,
                    error,
                );
                if (errorCode != null) {
                    // disabled because this needs warnings
                    // eslint-disable-next-line no-console
                    console.warn(statusCode + ' status: no handler for error code: ' + errorCode);
                }

                return;
            }

            if (httpErrorDescription.ignore) {
                return;
            }

            const enableReport: boolean = httpErrorDescription.report ?? true;
            displayRawErrors = httpErrorDescription.showRawMessage ?? displayRawErrors;

            let message = '';
            if (httpErrorDescription.body) {
                if (
                    typeof httpErrorDescription.body === 'string' ||
                    httpErrorDescription.body instanceof String
                ) {
                    message = httpErrorDescription.body as string;
                } else {
                    message = httpErrorDescription.body(additionalProperties);
                }
            }
            let title: string = httpErrorDescription.title ?? '';
            const sticky: boolean = forceOpen || (httpErrorDescription.sticky ?? false);
            const link: string = httpErrorDescription.link ?? '';

            const checkForNull: string[] = [message, title];
            if (checkForNull.includes('')) {
                title = checkForNull.filter(value => value != '')[0];
                message = '';
            }

            this.toastService.showInfoEvent(
                title,
                true,
                sticky,
                message,
                link,
                false,
                displayRawErrors ? rawMessage : null,
                enableReport,
                sentryEventId,
                error,
            );
        }
    }
}
