<div (click)="showDropdown = true" class="dropdown">
    <button [style.background-color]="backgroundColor" id="language-dropdown" mat-stroked-button>
        <span class="fi fi-{{ languages[selectedLanguage].image }}"></span
        >{{ languages[selectedLanguage].name }}
    </button>
    <div [class.show]="this.showDropdown" class="dropdown-content">
        <a
            (click)="languageSwitcherService.changeLanguage($any(languagePair.key))"
            *ngFor="let languagePair of languages | keyvalue"
            ><span class="fi fi-{{ languagePair.value.image }}"></span>{{ languagePair.value.name }}
        </a>
    </div>
</div>
