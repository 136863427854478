import { Injectable } from '@angular/core';
import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import { UserInfo } from '@upscore-mobility-audit/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UpscoreMobilityAuditUserDataService extends AbstractUserDataService<UserInfo> {
    public showFeature(feature: FeatureFlag): boolean {
        // TODO migrate code to use this function when okay
        const user = this._user$.value;

        if (feature === FeatureFlag.IS_ALLOWED_TO_ANALYSE) {
            return user != null
                ? user.maxCalculations == null || user.currentCalculations < user.maxCalculations
                : false;
        }

        return user != null ? user.features[feature] === true : false;
    }

    public showFeature$(feature: FeatureFlag): Observable<boolean> {
        // special handling for IS_ALLOWED_TO_ANALYSE feature flag
        // because it isn't a part of the feature flags in the user object
        if (feature === FeatureFlag.IS_ALLOWED_TO_ANALYSE) {
            return this.user$.pipe(
                map(user => {
                    return (
                        user != null &&
                        (user.maxCalculations == null ||
                            user.currentCalculations < user.maxCalculations)
                    );
                }),
            );
        }

        return this.user$.pipe(
            map(user => {
                return user?.features[feature] === true;
            }),
        );
    }

    public isUserType(userType: 'PREMIUM' | 'LIGHT' | 'VIEW_ONLY'): boolean {
        return this._user$.value != null ? this._user$.value.accountType === userType : false;
    }

    public isUserType$(userType: 'PREMIUM' | 'LIGHT' | 'VIEW_ONLY'): Observable<boolean> {
        return this.user$.pipe(
            map(user => {
                return user?.accountType === userType;
            }),
        );
    }
}
