import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingIndicatorComponent, NotFoundComponent } from './components';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { SankeyChartComponent } from './components/sankey-chart/sankey-chart.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { TriplyCardComponent } from './components/triply-card/triply-card.component';
import { EntityAddressComponent } from './entity-address/entity-address.component';
import { MaterialModule } from './modules/material/material.module';

@NgModule({
    declarations: [
        BarChartComponent,
        TriplyCardComponent,
        LanguageSwitchComponent,
        SelectSearchComponent,
        BackButtonComponent,
        EntityAddressComponent,
        LoadingIndicatorComponent,
        SankeyChartComponent,
        DialogComponent,
        NotFoundComponent,
    ],
    imports: [CommonModule, ReactiveFormsModule, FormsModule, MaterialModule, RouterModule],
    exports: [
        BarChartComponent,
        TriplyCardComponent,
        LanguageSwitchComponent,
        MaterialModule,
        SelectSearchComponent,
        BackButtonComponent,
        EntityAddressComponent,
        LoadingIndicatorComponent,
        SankeyChartComponent,
        DialogComponent,
        NotFoundComponent,
    ],
})
export class UiLibraryModule {}
