/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { MobilityPropertiesService } from './services/mobility-properties.service';
import { PackagesService } from './services/packages.service';
import { ImprovementsService } from './services/improvements.service';
import { EmployeesService } from './services/employees.service';
import { ModeEstimationService } from './services/mode-estimation.service';
import { UserService } from './services/user.service';
import { CompanyLocationsService } from './services/company-locations.service';
import { EmployeeUploadService } from './services/employee-upload.service';
import { SharingService } from './services/sharing.service';
import { ScenarioCalculationService } from './services/scenario-calculation.service';
import { TimezoneService } from './services/timezone.service';
import { KpisService } from './services/kpis.service';
import { TripsService } from './services/trips.service';
import { EmployeeModeService } from './services/employee-mode.service';
import { JobsService } from './services/jobs.service';
import { ExportService } from './services/export.service';
import { AdditionalInfoService } from './services/additional-info.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MobilityPropertiesService,
    PackagesService,
    ImprovementsService,
    EmployeesService,
    ModeEstimationService,
    UserService,
    CompanyLocationsService,
    EmployeeUploadService,
    SharingService,
    ScenarioCalculationService,
    TimezoneService,
    KpisService,
    TripsService,
    EmployeeModeService,
    JobsService,
    ExportService,
    AdditionalInfoService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
