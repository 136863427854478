import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { PaginationId } from '../enums/pagination-id.enum';
import { ResizeService } from './resize.service';

export class PaginationController {
    public currentPage = 1;
    public pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(6);
    public maxPages$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public size$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(private readonly resizeService: ResizeService) {
        resizeService.resizeObservable$.subscribe(() => this.changePageSizeOnResize());
    }

    public get distinctPageSize$(): Observable<number> {
        return this.pageSize$.pipe(distinctUntilChanged());
    }

    public get distinctMaxPages$(): Observable<number> {
        return this.maxPages$.pipe(distinctUntilChanged());
    }

    public get distinctSize$(): Observable<number> {
        return this.size$.pipe(distinctUntilChanged());
    }

    /**
     * Is called when page is resized to determine the amount of items on screen
     */
    public changePageSizeOnResize(): void {
        const screenHeight: number = window.innerHeight;
        const screenWidth: number = window.innerWidth;

        let columns = 4;
        let rows = 4;

        if (screenHeight < 2016) {
            rows = 3;
            if (screenHeight < 1536) {
                rows = 2;
                if (screenHeight < 976) {
                    rows = 1;
                }
            }
        }

        if (screenWidth < 1905) {
            columns = 3;
            if (screenWidth < 1466) {
                columns = 2;
                if (screenWidth < 1022) {
                    columns = 1;
                }
            }
        }

        this.pageSize$.next(columns * rows);
    }

    /**
     * Changes page by amount
     * @param amount
     */
    public changePage(amount: number): void {
        this.currentPage = this.currentPage + amount;
    }

    public clear(): void {
        this.pageSize$.complete();
        this.maxPages$.complete();
        this.size$.complete();
    }
}

@Injectable({
    providedIn: 'root',
})
export class PaginationControllerService {
    public paginationControllerStore: Map<PaginationId, PaginationController> = new Map<
        PaginationId,
        PaginationController
    >();

    constructor(public readonly resizeService: ResizeService) {}

    public clear(): void {
        for (const value of this.paginationControllerStore.values()) {
            value.clear();
        }
        this.paginationControllerStore.clear();
    }

    public getPaginationController(id: PaginationId): PaginationController {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.paginationControllerStore.get(id)!;
    }

    public addPaginationController(id: PaginationId, controller: PaginationController): void {
        if (this.paginationControllerStore.has(id)) {
            this.paginationControllerStore.get(id)?.clear();
        }
        this.paginationControllerStore.set(id, controller);
    }
}
